import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import {
  useLazyGetPaymentCsvQuery,
  useLazyGetPaymentsQuery,
  useLazyGetRefundRequestsQuery,
} from "../../services/main";
import { Loader, showError } from "../../constants";
import { Payments } from "../../types/General";
import Pagination from "../../components/Pagination";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

const RefundRequests = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const [getRefundReq, { isLoading }] = useLazyGetRefundRequestsQuery();
  const [paymentCsv] = useLazyGetPaymentCsvQuery();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState(1);
  const [allRequests, setAllRequests] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const translate = useTranslation();
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });
  const [loading, setLoading] = useState(false);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const getUserCsv = async () => {
    try {
      const response = await paymentCsv({
        type: "user",
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        window?.open(response?.data);
      } else {
        console.log("error");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getRequests = async () => {
    setLoading(true);
    try {
      const response = await getRefundReq({
        query: debouncedSearchTerm.trim(),
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setAllRequests(response?.data?.data || []);
        setTotalCount(response?.data?.total);
      } else {
        setAllRequests([]);
      }
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getRequests();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Refund Requests</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            {/* <Box className="cards_header_right">
              <Button className="btn btn_primary" onClick={getUserCsv}>
                <FileDownloadIcon /> {translate?.dashboard?.ExportCsv}
              </Button>
            </Box> */}
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{translate?.main?.SNo}</TableCell>

                  <TableCell>Property Name</TableCell>
                  <TableCell>Refunded Amount</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>User Email</TableCell>
                  <TableCell>User Phone</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allRequests?.length > 0
                  ? allRequests?.map((row: any, i: any) => (
                      <TableRow key={row?._id}>
                        <TableCell align="center">
                          {(page - 1) * 10 + i + 1}
                        </TableCell>

                        <TableCell>
                          {row?.propertyDetail?.name || "-"}
                        </TableCell>
                        <TableCell>{row?.refundedAmount || "-"} KD</TableCell>

                        <TableCell>
                          {row?.userDetail?.fullName || "-"}
                        </TableCell>
                        <TableCell>{row?.userDetail?.email || "-"}</TableCell>
                        <TableCell>{`${row?.userDetail?.countryCode} ${
                          row?.userDetail?.phone || "-"
                        } `}</TableCell>
                      </TableRow>
                    ))
                  : ""}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        {allRequests?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={allRequests}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>
    </MainContainer>
  );
};

export default RefundRequests;
