// @ts-nocheck
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  SelectChangeEvent,
  Typography,
  Switch,
  Autocomplete,
} from "@mui/material";
import * as Yup from "yup";
import { isString, useFormik } from "formik";
import {
  useAddSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useLazyGetSubscriptionByIdQuery,
} from "../../services/subscription";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, Subscription } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import { isNumber } from "../../utils/validations";
import useTranslation from "../../hooks/Translation";
import {
  useAddDiscountMutation,
  useLazyGetAllUsersQuery,
  useLazyGetDiscountByIdQuery,
  useUpdateDiscountMutation,
} from "../../services/discount";
import moment from "moment";

const AddDiscount = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const [addDiscount] = useAddDiscountMutation();
  const [updateSubscription] = useUpdateSubscriptionMutation();
  const [discountDetails] = useLazyGetDiscountByIdQuery();
  const [updateDiscount] = useUpdateDiscountMutation();
  const navigate = useNavigate();
  const [subsById, setSubsById] = useState<any>();
  const [error, setError] = useState(false);
  const [discountType, setDiscountType] = useState("");
  const [couponType, setCouponType] = useState("");
  const [allUsers] = useLazyGetAllUsersQuery();
  const [userData, setUserData] = useState<any[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  console.log(selectedUsers, "selectedUsers");
  const ids = selectedUsers.map((item: any) => item._id);

  const [selected, setSelected] = useState("");

  const [featureEnabled, setFeatureEnabled] = useState(false); // Set to true or false based on your logic
  const [loading, setLoading] = useState(false);

  const handleSwitchChange = () => {
    setFeatureEnabled(!featureEnabled);
    // Add any other logic you want to execute when the switch changes state
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setDiscountType(event.target.value as string);
  };

  const handleCouponTypeChange = (event: SelectChangeEvent) => {
    setCouponType(event.target.value as string);
  };

  const handleUserChange = (event: SelectChangeEvent, value: any[]) => {
    setSelectedUsers(value);
  };

  const handleCodeChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value as string);
  };

  const [switchValue, setSwitchValue] = useState(1);
  console.log(switchValue);

  const translate = useTranslation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: subsById?.discountValue || "",
      planName: subsById?.discountName || "",
      minAmount: subsById?.minAmountToApply || "",
      // maximumDiscountAmount: subsById?.maximumDiscountAmount || "",
      startDate: id ? moment(subsById?.startDate).format("YYYY-MM-DD") : "",
      endDate: id ? moment(subsById?.endDate).format("YYYY-MM-DD") : "",
    },
    validationSchema: Yup.object({
      price: Yup.string()
        .required("This field is required")
        .max(20, "Maximum 20 character are allowed"),
      planName: Yup.string()
        .required("This field is required")
        .max(20, "Maximum 20 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      // discountCode: Yup.string()
      //   .required("This field is required")
      //   .max(20, "Maximum 20 character are allowed"),
      minAmount: Yup.string()
        .required("This field is required")
        .max(20, "Maximum 20 character are allowed"),
      // maximumDiscountAmount:
      //   discountType === "1"
      //     ? Yup.string().required("This field is required")
      //     : Yup.string().notRequired(),
      startDate: Yup.string().required("This field is required"),
      endDate: Yup.string().required("This field is required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const ids = selectedUsers.map((item: any) => item._id);

      const data = {
        // couponType: couponType,
        discountName: formik?.values?.planName,
        discountType: discountType,
        discountValue: formik?.values?.price,
        minAmountToApply: values.minAmount,
        // maximumDiscountAmount: values.maximumDiscountAmount,
        users: ids,
        startDate: values.startDate,
        endDate: values.endDate,
        // discountCode: values.discountCode,
        forAllUsers: selected === "2" ? true : false,
      };

      console.log(data, "body for plan");

      if (id && id !== "add") {
        try {
          setLoading(true);
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await updateDiscount({
            id: id,
            encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            setLoading(false);
            showToast("Promotional Code Updated successfully");
            navigate("/manage-discount");
          } else {
            setLoading(false);
            showError(response?.message || "");
          }
        } catch (error: any) {
          setLoading(false);
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          setLoading(true);
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await addDiscount(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            setLoading(false);
            showToast("Promotional Code Added Successfully");
            formik.resetForm();
            navigate("/manage-discount");
          } else {
            setLoading(false);
            showError(response?.message || "");
          }
        } catch (error: any) {
          setLoading(false);
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const formatNumberWithCommas = (number: any) => {
    if (number > 0) {
      const formattedNumber = number.toLocaleString("AED");
      return `${formattedNumber}`;
    }
    return "";
  };

  const getCategoryDetail = async (id: string | undefined) => {
    try {
      const res: any = await discountDetails({ id: id }).unwrap();
      if (res?.statusCode === 200) {
        setSubsById(res?.data);
        setDiscountType(String(res?.data?.discountType));
        setCouponType(String(res?.data?.couponType));

        if (res?.data?.users?.length) {
          setSelectedUsers(res?.data?.userDetails);
          setSelected("1");
        } else {
          setSelected("2");
        }
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const getAllUsers = async () => {
    try {
      const res: any = await allUsers({ query: "" }).unwrap();
      if (res?.statusCode === 200) {
        setUserData(res?.data?.users);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (id && id !== "add") {
      getCategoryDetail(id);
    }
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          {state?.type === "edit" ? (
            <h1 className="mn_hdng">Edit Promotional Code</h1>
          ) : (
            <h1 className="mn_hdng">Add Promotional Code</h1>
          )}
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-discount");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Discount Name
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="planName"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder="Discount Name"
                    onBlur={formik.handleBlur}
                    value={formik.values.planName}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.planName && formik.errors.planName
                    }
                  />
                </Grid>
                {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Discount Code
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="discountCode"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder="Discount Code"
                    onBlur={formik.handleBlur}
                    value={formik.values.discountCode}
                    onChange={formik.handleChange}
                    helperText={
                      formik.touched.discountCode && formik.errors.discountCode
                    }
                  />
                </Grid> */}

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Select Discount Type
                  </Typography>

                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      fullWidth
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={discountType}
                      // multiple
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleTypeChange}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>

                      <MenuItem value="1">Percent</MenuItem>
                      <MenuItem value="2">Flat</MenuItem>
                    </Select>
                    {error && !discountType ? (
                      <h6 className="err_msg">Please Select Discount Type</h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>

                {/* {discountType === "1" ? (
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Maximum Discount Amount
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="maximumDiscountAmount"
                      variant="outlined"
                      fullWidth
                      className="text_field"
                      placeholder="Discount Value"
                      onBlur={formik.handleBlur}
                      value={formatNumberWithCommas(
                        formik.values.maximumDiscountAmount
                      )}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                        }
                        formik.handleChange(val);
                      }}
                      helperText={
                        formik.touched.maximumDiscountAmount &&
                        formik.errors.maximumDiscountAmount
                      }
                    />
                  </Grid>
                ) : null} */}

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Discount Value
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="price"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder="Discount Value"
                    onBlur={formik.handleBlur}
                    value={formatNumberWithCommas(formik.values.price)}
                    onChange={(e) => {
                      let value = e.target.value;

                      // Remove commas if present before validation
                      value = value.replace(/,/g, "");

                      // Check if discount type is 1 and restrict input to 0-100
                      if (discountType === "1") {
                        if (parseFloat(value) > 100) {
                          value = "100";
                        }
                      }

                      // Format the value with commas
                      const formattedValue = formatNumberWithCommas(value);

                      // Update the form value
                      formik.handleChange({
                        target: {
                          name: e.target.name,
                          value: formattedValue,
                        },
                      });
                    }}
                    helperText={formik.touched.price && formik.errors.price}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Minimum Amount To Apply
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="minAmount"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder="Minimum Amount To Apply"
                    onBlur={formik.handleBlur}
                    value={formatNumberWithCommas(formik.values.minAmount)}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                      }
                      formik.handleChange(val);
                    }}
                    helperText={
                      formik.touched.minAmount && formik.errors.minAmount
                    }
                  />
                </Grid>

                {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Coupon Type</Typography>

                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      fullWidth
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={couponType}
                      // multiple
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleCouponTypeChange}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>

                      <MenuItem value="1">Highlighted</MenuItem>
                      <MenuItem value="2">Normal</MenuItem>
                      <MenuItem value="3">Both</MenuItem>
                    </Select>
                    {error && !couponType ? (
                      <h6 className="err_msg">Please Select Coupon Type</h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid> */}

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Apply Code For
                  </Typography>

                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      fullWidth
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selected}
                      // multiple
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleCodeChange}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>

                      <MenuItem value="1">Selected Users</MenuItem>
                      <MenuItem value="2">All Users</MenuItem>
                    </Select>
                    {error && !selected ? (
                      <h6 className="err_msg">Please Select Type</h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>

                {selected === "1" ? (
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Select Users
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Autocomplete
                        multiple
                        className="multi_select_div"
                        options={userData}
                        getOptionLabel={(option) =>
                          option.fullName || "No Name"
                        }
                        value={selectedUsers}
                        onChange={handleUserChange}
                        renderInput={(params) => (
                          <TextField
                            className="autoText"
                            {...params}
                            variant="standard"
                            placeholder="Search users"
                            //   error={
                            //     formik.touched.receiverId &&
                            //     Boolean(formik.errors.receiverId)
                            //   }
                            //   helperText={
                            //     formik.touched.receiverId &&
                            //     formik.errors.receiverId
                            //       ? formik.errors.receiverId.toString()
                            //       : ""
                            //   }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                ) : null}

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Start Date</Typography>
                  <TextField
                    hiddenLabel
                    type={"date"}
                    name="startDate"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder={"Start Date"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.startDate}
                    helperText={
                      formik.touched.startDate && formik.errors.startDate
                    }
                    inputProps={{
                      min: new Date().toISOString().split("T")[0],
                    }}
                    onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                    InputProps={{ disableUnderline: true }}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">End Date</Typography>
                  <TextField
                    hiddenLabel
                    type={"date"}
                    name="endDate"
                    className="text_field"
                    variant="outlined"
                    disabled={!formik.values.startDate}
                    fullWidth
                    placeholder={"End Date"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.endDate}
                    helperText={formik.touched.endDate && formik.errors.endDate}
                    inputProps={{
                      min: formik.values.startDate,
                    }}
                    onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                    InputProps={{ disableUnderline: true }}
                  />
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                  onClick={() => setError(true)}
                >
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddDiscount;
