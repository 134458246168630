import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import EditText from "../../components/EditText";
import PhoneInput from "react-phone-input-2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "react-phone-input-2/lib/bootstrap.css";
import { useLazyGetCmsQuery, usePostCmsMutation } from "../../services/cms";
import useAuth from "../../hooks/useAuth";
import { showError, showToast } from "../../constants";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import EmailIcon from "@mui/icons-material/Email";
import useTranslation from "../../hooks/Translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCms = () => {
  const userData = useAuth();
  const [getAllCms] = useLazyGetCmsQuery();
  const [postCms, { isLoading }] = usePostCmsMutation();

  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [privacyPolicyAr, setPrivacyPolicyAr] = useState<string>("");
  const [cmsId, setCmsId] = useState<string>("");
  const [phoneCode, setPhoneCode] = useState("+965");
  const [waPhoneCode, setWaPhoneCode] = useState("+965");
  const [legal, setLegal] = useState<string>("");
  const [legalAr, setLegalAr] = useState<string>("");
  const [termsAndConditions, setTermsAndConditions] = useState<string>("");
  const [value, setValue] = React.useState(0);
  const [error, setError] = useState(false);
  const translate = useTranslation();
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Manage Subscription Plans"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  const getCmsDetail = async () => {
    try {
      const res = await getAllCms({}).unwrap();
      if (res?.statusCode === 200 && res?.data) {
        formik.setFieldValue("email", res?.data?.contactUs?.email);
        formik.setFieldValue("phone", res?.data?.contactUs?.phone);
        formik.setFieldValue("waPhone", res?.data?.contactUs?.whatsappPhone);
        formik.setFieldValue("normalCred", res?.data?.normalCredPrice);
        formik.setFieldValue("highlightedCred", res?.data?.highlightCredPrice);
        formik.setFieldValue("agentRequestPrice", res?.data?.agentRequestPrice);

        setPrivacyPolicy(res?.data?.privacyPolicy || " ");
        setPrivacyPolicyAr(res?.data?.privacyPolicy_ar || " ");
        setLegal(res?.data?.legal);
        setLegalAr(res?.data?.termsAndConditions_ar);
        // setTermsAndConditions(res?.data?.termsAndConditions);
        setCmsId(res?.data?._id);
        setPhoneCode(res?.data?.contactUs?.dialCode || "");
        setWaPhoneCode(res?.data?.contactUs?.whatsappDialCode || "");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };
  const handleChangeWaPhone = (phone: any, country: any) => {
    formik.setFieldValue("waPhone", phone?.replace(country.dialCode, ""));
    setWaPhoneCode(country?.dialCode);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      phone: "",
      waPhone: "",
      normalCred: "",
      highlightedCred: "",
      agentRequestPrice: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),

      phone: Yup.string()
        .required("required")
        .min(6, "Phone number must be at least 6 digits"),
      waPhone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 digits"),
      normalCred: Yup.string().required("required"),
      highlightedCred: Yup.string().required("required"),
      agentRequestPrice: Yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      let hasError = false;

      if (
        value === 1 &&
        (!privacyPolicy.trim() || privacyPolicy.trim().length < 8)
      ) {
        setError(true);
        hasError = true;
      } else if (value === 2 && (!legal.trim() || legal.trim().length < 8)) {
        setError(true);
        hasError = true;
      } else if (
        value === 2 &&
        (!legalAr.trim() || legalAr.trim().length < 8)
      ) {
        setError(true);
        hasError = true;
      } else if (
        value === 1 &&
        (!privacyPolicyAr.trim() || privacyPolicyAr.trim().length < 8)
      ) {
        setError(true);
        hasError = true;
      } else {
        setError(false);
      }

      if (hasError) {
        // If there is an error, don't proceed with form submission
        formik.setSubmitting(false);
        return;
      }

      const data = {
        contactUs: {
          email: formik.values.email,
          phone: formik.values.phone,
          dialCode: phoneCode,
          whatsappPhone: formik.values.waPhone,
          whatsappDialCode: waPhoneCode,
        },

        privacyPolicy: privacyPolicy,
        privacyPolicy_ar: privacyPolicyAr,
        termsAndConditions: legal,
        termsAndConditions_ar: legalAr,
        normalCredPrice: formik.values.normalCred,
        highlightCredPrice: formik.values.highlightedCred,
        agentRequestPrice: formik.values.agentRequestPrice,
      };
      console.log(data);

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await postCms({
          encryptedBody,
          userId: cmsId,
        }).unwrap();

        if (response?.statusCode === 200) {
          showToast("Cms updated successfully");
        }
      } catch (error: any) {
        console.log(error, "error");
        showError(error?.data?.message || "");
      }
    },
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getCmsDetail();
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate?.main?.ManageSettings}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label={translate?.main?.contactSupport} {...a11yProps(0)} />
              <Tab label={translate?.main?.privacyPolicy} {...a11yProps(1)} />
              <Tab label={translate?.main?.TermsConditions} {...a11yProps(2)} />
              <Tab label={translate?.main?.CreditPrice} {...a11yProps(3)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {translate?.main?.Email}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder="Email"
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      inputProps={{ maxLength: 50 }}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {translate?.main?.PhoneNumber}
                    </Typography>
                    <PhoneInput
                      country={"us"}
                      value={phoneCode + formik.values.phone}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {translate?.main?.WhatsappPhoneNumber}
                    </Typography>
                    <PhoneInput
                      country={"us"}
                      value={waPhoneCode + formik.values.waPhone}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                      onChange={(phone, country) =>
                        handleChangeWaPhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.waPhone && formik.errors.waPhone ? (
                      <h6 className="err_msg">
                        {formik.touched.waPhone && formik.errors.waPhone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translate?.main?.Save}
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <form onSubmit={formik.handleSubmit}>
                <h3>{translate?.main?.PrivacyPolicyEnglish}</h3>
                <EditText
                  content={privacyPolicy}
                  setContent={setPrivacyPolicy}
                />
                {error ? (
                  <h6 className="err_msg">
                    {translate?.main?.Pleaseenterprivacypolicy}
                  </h6>
                ) : (
                  ""
                )}
                <h3>{translate?.main?.PrivacyPolicyArabic}</h3>
                <EditText
                  content={privacyPolicyAr}
                  setContent={setPrivacyPolicyAr}
                />

                <div className="form_btn">
                  {hidePermission?.isAdd ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      {translate?.main?.Save}
                    </Button>
                  ) : null}
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <form onSubmit={formik.handleSubmit}>
                <h3>{translate?.main?.TermsConditionsEnglish}</h3>
                <EditText content={legal} setContent={setLegal} />
                {error ? (
                  <h6 className="err_msg">
                    {translate?.main?.PleaseenterTermsandConditions}
                  </h6>
                ) : (
                  ""
                )}
                <h3>{translate?.main?.TermsConditionsArabic}</h3>
                <EditText content={legalAr} setContent={setLegalAr} />
                {error ? (
                  <h6 className="err_msg">
                    {translate?.main?.PleaseenterTermsandConditions}
                  </h6>
                ) : (
                  ""
                )}
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translate?.main?.Save}
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate?.main?.NormalCredits}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="normalCred"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder="Normal credits"
                      onBlur={formik.handleBlur}
                      value={formik.values.normalCred}
                      onChange={formik.handleChange}
                      inputProps={{ maxLength: 50 }}
                      helperText={
                        formik.touched.normalCred && formik.errors.normalCred
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate?.main?.HighlightedCredits}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="highlightedCred"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder="Highlighted credits"
                      onBlur={formik.handleBlur}
                      value={formik.values.highlightedCred}
                      onChange={formik.handleChange}
                      inputProps={{ maxLength: 50 }}
                      helperText={
                        formik.touched.highlightedCred &&
                        formik.errors.highlightedCred
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate?.main?.agentRequestPrice}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="agentRequestPrice"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder="Request Price"
                      onBlur={formik.handleBlur}
                      value={formik.values.agentRequestPrice}
                      onChange={formik.handleChange}
                      inputProps={{ maxLength: 50 }}
                      helperText={
                        formik.touched.agentRequestPrice &&
                        formik.errors.agentRequestPrice
                      }
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translate?.main?.Save}
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageCms;
