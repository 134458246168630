export const ar = {
  globals: {
    save: "save",
    submit: "submit",
  },
  auth: {
    emailId: " البريد الإلكتروني",
    password: "كلمة المرور",
    remember: "تذكرني",
    forgotPassword: "نسيت كلمة المرور",
    oldPass: "كلمة المرور القديمة",
    confmPas: "تأكيد كلمة المرور",
    otpVerification: "التحقق من OTP",
    verify: "تحقق",
    resendOtp: "إعادة إرسال OTP",
    login: "تسجيل الدخول",
    resendPass: "إعادة تعيين كلمة المرور",
    editProfile: "تعديل الملف الشخصي",
    firstName: "الاسم الأول",
    lastName: "اسم العائلة",
    email: "البريد الإلكتروني",
    phone: "رقم الهاتف",
  },

  main: {
    Sort: "الترتيب حسب الحروف الهجائية",
    Sort2: "الترتيب حسب لا. من الخصائص",
    Option1: "من الألف إلى الياء",
    Option2: "ي إلى أ",
    Option3: "من أسفل إلى أعلى",
    Option4: "عالية إلى منخفضة",
    image: "صورة",
    addCategory: "إضافة نوع عقار",
    editCategory: "تعديل نوع عقار",
    catName: "اسم العقار",
    selectCat: "اختر نوع العقار",
    address: "العنوان",
    addSubAdmin: "إضافة مسؤول فرعي",
    addEdit: "إضافة/تعديل",
    accountStatus: "حالة الحساب",
    viewUser: "عرض العميل",
    editCustomer: "تعديل العميل",
    question: "سؤال",
    answer: "إجابة",
    contactSupport: "التواصل مع الدعم",
    privacyPolicy: "سياسة الخصوصية",
    aboutUs: "معلومات عنا",
    terms: "الشروط والأحكام",
    changeLang: "تغيير اللغة",
    notification: "الإشعارات",
    showing: "عرض",
    items: "عناصر",
    active: "نشط",
    Inactive: "غير نشط",
    subscription: "الاشتراك",
    message: "رسالة",
    receiver: "المستلم",
    broadcast: "بث",
    atLeast1Feature: "يجب اختيار ميزة واحدة على الأقل",
    loginToRe: "تسجيل الدخول إلى إدارة العقارات",
    date: "التاريخ",
    credits: "الرصيد",
    addCredits: "إضافة رصيد",
    export: "تصدير CSV",
    subCat: "الفئة الفرعية",
    addSubCat: "إضافة فئة فرعية",
    governorate: "المحافظة",
    propertyName: "اسم العقار",
    confirmDelete: "هل أنت متأكد أنك تريد حذف هذا",
    Yes: "نعم",
    No: "لا",
    Back: "خلف",
    Delete: "يمسح",
    Title: "عنوان",
    SNo: "رقم S",
    AcountStatus: "حالة الحساب",
    Action: "فعل",
    Status: "حالة",
    Name: "اسم",
    Description: "وصف",
    Price: "سعر",
    Email: "بريد إلكتروني",
    PhoneNo: "رقم الهاتف",
    PlanPrice: "سعر الخطة",
    PlanName: "اسم الخطة",
    TotalCreditsLeft: "إجمالي الاعتمادات المتبقية",
    TotalCreditsPending: "مجموع الاعتمادات المعلقة",
    Totalcreditsused: "إجمالي الاعتمادات المستخدمة",
    TotalCredits: "إجمالي الائتمان",
    TotalCredits2: "إجمالي الاعتمادات (الاشتراك)",
    TotalCreditsLeft2: "إجمالي الاعتمادات المتبقية (الاشتراك)",
    Totalcreditsused2: "إجمالي الاعتمادات المستخدمة (الاشتراك)",
    PlanDetails: "تفاصيل الخطة",
    acceptRequestsLeft: "الطلبات المعلقة",
    acceptRequests: "إجمالي الطلبات",
    Governorate: "محافظة",
    NoAreaFound: "لم يتم العثور على منطقة",
    AddGovernorate: "أضف المحافظة",
    ManageAreas: "إدارة المناطق",
    NormalListingCredits: "اعتمادات القائمة العادية",
    HighlightedListingCredits: "أرصدة القائمة المميزة",
    ManageSubscriptionPlan: "إدارة خطة الاشتراك",
    AddPlan: "أضف خطة",
    Image: "صورة",
    CategoryName: "اسم التصنيف",
    SubCategory: "تصنيف فرعي",
    NoCategoryFound: "لم يتم العثور على فئة",
    ManageCategories: "إدارة الفئات",
    AddSubcategory: "إضافة فئة فرعية",
    ManageSubAdmin: "إدارة المشرف الفرعي",
    Phone: "هاتف",
    Nosubadminsfound: "لم يتم العثور على مشرفين فرعيين.",
    Add: "يضيف",
    PropertiesforSale: "عقارات للبيع",
    PropertiesforRent: "عقارات للإيجار",
    PropertiesforExchange: "خصائص للتبادل",
    MostViewedProperties: "العقارات الأكثر مشاهدة",
    MostSearchedProperties: "العقارات الأكثر بحثا",
    Pleaseselectcategory: "الرجاء تحديد الفئة",
    Type: "يكتب",
    Location: "موقع",
    CustomerName: "اسم الزبون",
    AgentName: "اسم العميل",
    PropertyCategory: "فئة العقار",
    PropertyName: "اسم الخاصية",
    NoPropertiesFound: "لم يتم العثور على خصائص",
    Mostsearchedbyarea: "الأكثر بحثا حسب المنطقة",
    UserPreferences: "خيارات المستخدم",
    All: "الجميع",
    ManageProperties: "إدارة الخصائص",
    VideoTitle: "عنوان مقطع الفيديو",
    AddTutorial: "إضافة البرنامج التعليمي",
    NoTutorialFound: "لم يتم العثور على البرنامج التعليمي",
    ManageTutorials: "إدارة البرامج التعليمية",
    ManagePayments: "إدارة المدفوعات",
    PaymentMode: "طريقة الدفع",
    PaymentType: "نوع الدفع",
    Amount: "كمية",
    TotalEarnings: "الأرباح الكلية",
    ReportsandAnalytics: "التقارير والتحليلات",
    EditProfile: "تعديل الملف الشخصي",
    Save: "يحفظ",
    PhoneNumber: "رقم التليفون",
    FirstName: "الاسم الأول",
    LastName: "اسم العائلة",
    FullName: "الاسم الكامل",
    EditAgentProfile: "تحرير ملف تعريف الوكيل",
    EditTutorial: "تحرير البرنامج التعليمي",
    UploadVideos: "تحميل أشرطة الفيديو",
    DescriptionArabic: "الوصف (عربي)",
    TitleArabic: "العنوان (عربي)",
    ContactSupport: "اتصل بالدعم",

    PrivacyPolicy: "سياسة الخصوصية",
    TermsConditions: "البنود و الظروف",
    CreditPrice: "سعر الائتمان",
    ManageSettings: "إدارة الإعدادات",
    WhatsappPhoneNumber: "رقم هاتف واتس اب",
    PrivacyPolicyEnglish: "سياسة الخصوصية (الإنجليزية)",

    Pleaseenterprivacypolicy: "الرجاء إدخال سياسة الخصوصية",
    PrivacyPolicyArabic: "Privacy Policy ( Arabic )",
    PleaseenterTermsandConditions: "سياسة الخصوصية (العربية)",
    TermsConditionsArabic: "الشروط والأحكام (العربية)",
    TermsConditionsEnglish: "الشروط والأحكام (الإنجليزية)",
    NormalCredits: "الاعتمادات العادية",
    HighlightedCredits: "الاعتمادات المميزة",
    agentRequestPrice: "سعر طلب العقار لكل وكيل",
    TotalUsers: "إجمالي المستخدمين",
    ManageNotifications: "إدارة الإخطارات",
    AddNotification: "إضافة الإخطار",
    NoNotificationFound: "لم يتم العثور على أي إشعار",
    EditUserProfile: "تحرير ملف تعريف المستخدم",
    ManageFaqs: "إدارة الأسئلة الشائعة",

    QuestionEnglish: "السؤال (الإنجليزية)",
    QuestionArabic: "سؤال (عربي)",
    AnswerEnglish: "الإجابة (الإنجليزية)",
    AnswerArabic: "الإجابة (العربية)",
    Remove: "يزيل",
    Arabic: "عربي",
    English: "إنجليزي",
    PaymentDetails: "بيانات الدفع",
    NotificationTitle: "عنوان الإخطار",
    SubscriptionPlan: "خطة الاشتراك",
    SelectReceiver: "حدد جهاز الاستقبال",
    Edit: "يحرر",
    Module: "وحدة",
    View: "منظر",
    EditSubscriptionPlan: "تحرير خطة الاشتراك",
    PlanValidity: "صلاحية الخطة",
    PlanFeatures: "ميزات الخطة",
    Feature: "ميزة",
    AddSubscriptionPlan: "إضافة خطة الاشتراك",
    EditSubAdmin: "تحرير المشرف الفرعي",
    AddSubAdmin: "إضافة مشرف فرعي",
    Showrequesttoagents: "عرض الطلب على الوكلاء",
    ManageRequest: "إدارة الطلب",
    Month: "شهر",
    Months: "شهور",
    PleaseselectValidity: "الرجاء تحديد الصلاحية",
    Category: "فئة",
    NoSubcategoryFound: "لم يتم العثور على فئة فرعية",
    ManageSubCategory: "إدارة الفئة الفرعية",
    Validity: "صلاحية",
    Area: "منطقة",
    Please: "لو سمحت",
    Total: "المجموع",
    Map: "خريطة",
    Link: "وصلة",
    Agent: "عامل",
    Normal: "طبيعي",
    Hightlighted: "أبرز",
    For: "ل",
    Upload: "رفع",
    Property: "ملكية",
    Yourbrowserdoesnotsupportthevideotag:
      "متصفحك الحالي لا يدعم تشغيل الفيديو.",
    Totalareainmetersquare: "المساحة الإجمالية بالمتر المربع",
  },
  dashboard: {
    dashboard: "لوحة التحكم",
    totalUsers: "إجمالي المستخدمين",
    totalProperty: "إجمالي العقارات",
    forSale: "عقارات للبيع",
    forRent: "عقارات للإيجار",
    forExchange: "عقارات للتبادل",
    earnings: "إجمالي الأرباح",
    avgEarnings: "متوسط الأرباح الشهرية",
    byArea: "العقارات المبحوثة حسب المنطقة",
    Earning: "الأرباح",
    User: "المستخدمين",
    Property: "ملكيات",
    ExportCsv: "تصدير ملف CSV",
    Hourly: "ساعيا",
    Weekly: "أسبوعي",
    Monthly: "شهريا",
    Yearly: "سنوي",
    Select: "يختار",
  },
  sideBar: {
    dashboard: "لوحة التحكم",
    subAdmin: "إدارة المسؤولين الفرعيين",
    user: "إدارة المستخدمين",
    agent: "إدارة الوكلاء",
    plans: "إدارة خطط الاشتراك",
    category: "إدارة الفئات",
    property: "إدارة العقارات",
    areas: "إدارة المناطق",
    tutorial: "الدروس الترويجية",
    payments: "إدارة الدفعات",
    noti: "إدارة الإشعارات",
    reports: "التقارير والتحليلات",
    faqs: "إدارة الأسئلة الشائعة",
    setting: "الإعدادات",
    notifications: "إدارة الإخطارات",
    analytics: "التحليلات",
  },
  error: {
    required: "هذا الحقل مطلوب",
    validation1: "الرجاء اختيار فيديو صالح",
    maxValidation: "الحد الأقصى المسموح به 80 حرفًا",
    minValidation: "الحد الأدنى المطلوب 3 أحرف",
    noData: "لم يتم العثور على نتائج",
    titlereq: "العنوان مطلوب",
    descReq: "الوصف مطلوب",
    noFaq: "لم يتم العثور على قائمة الأسئلة الشائعة",
    emailReq: "البريد الإلكتروني مطلوب!",
    validEmail: "أدخل عنوان بريد إلكتروني صالح!",
    firstReq: "الاسم الأول مطلوب",
    lastReq: "اسم العائلة مطلوب",
    phoneReq: "رقم الهاتف مطلوب",
    minPhone: "يجب أن يكون رقم الهاتف 6 أرقام على الأقل",
    maxPhone: "يجب أن يكون رقم الهاتف 16 رقمًا كحد أقصى",
    subAdminCreated: "تم إنشاء المسؤول الفرعي بنجاح",
    subAdminUpdate: "تم تحديث المسؤول الفرعي بنجاح",
    userUpdate: "تم تحديث المستخدم بنجاح",
    statusUpdate: "تم تحديث الحالة بنجاح",
    subAdminDel: "تم حذف المسؤول الفرعي بنجاح",
    userDel: "تم حذف المستخدم بنجاح",
    max100: "الحد الأقصى المسموح به 100 حرف",
    max250: "الحد الأقصى المسموح به 250 حرفًا",
    max500: "الحد الأقصى المسموح به 500 حرف",
    catAdd: "تم إضافة الفئة بنجاح",
    catUpdate: "تم تحديث الفئة بنجاح",
    settingUpdate: "تم تحديث الإعدادات بنجاح",
    faqAdd: "تم إضافة الأسئلة الشائعة بنجاح",
    faqDel: "تم حذف الأسئلة الشائعة بنجاح",
    cmsUpdate: "تم تحديث CMS بنجاح",
    messageReq: "الرسالة مطلوبة",
    notiAdd: "تم إضافة الإشعار بنجاح",
    notiDel: "تم حذف الإشعار بنجاح",
    logutReq: "هل أنت متأكد من أنك تريد تسجيل الخروج؟",
  },

  plans: {
    planName: "اسم الخطة",
    planValid: "صلاحية الخطة",
    planPrice: "سعر الخطة",
    feature: "الميزات",
  },
  user: {
    Actions: "أجراءات",
    Credits: "الاعتمادات",
    AccountStatus: "حالة الحساب",
    Email: "بريد إلكتروني",
    PhoneNo: "رقم الهاتف",
    Name: "اسم",
    SNo: "رقم S",
    ManageUsers: "ادارة المستخدمين",
    ExportCsv: "تصدير ملف CSV",
    SearchHere: "ابحث هنا",
    Showing: "عرض",
    items: "أغراض",
    AddCredits: "إضافة الاعتمادات",
    HighlightedCredits: "الاعتمادات المميزة",
    NormalCredits: "الاعتمادات العادية",
    ViewUserProfile: "عرض ملف تعريف المستخدم",
  },
  agents: {
    Actions: "أجراءات",
    Credits: "الاعتمادات",
    AccountStatus: "حالة الحساب",
    Email: "بريد إلكتروني",
    PhoneNo: "رقم الهاتف",
    Name: "اسم",
    SNo: "رقم S",
    ManageAgents: "إدارة الوكلاء",
    ExportCsv: "تصدير ملف CSV",
    SearchHere: "ابحث هنا",
    Showing: "عرض",
    items: "أغراض",
    AddCredits: "إضافة الاعتمادات",
    HighlightedCredits: "الاعتمادات المميزة",
    NormalCredits: "الاعتمادات العادية",
    NoAgentFound: "لم يتم العثور على وكيل",
    Cancel: "يلغي",
    Add: "يضيف",
    Agent: "عامل",
    ViewAgentProfile: "عرض الملف التعريفي للوكيل",
  },
};
