import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  useLazyGetSubAdminByIdQuery,
  useAddSubAdminMutation,
  useUpdateSubAdminByIdMutation,
} from "../../services/main";
import { CommonBody, SubAdmin } from "../../types/General";
import { showError, showToast } from "../../constants";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { isString } from "../../utils/validations";
import PhoneInput from "react-phone-input-2";
import useTranslation from "../../hooks/Translation";

interface DataType {
  _id?: string;
  name: string;
  isAdd: boolean;
  isView: boolean;
  isDelete?: boolean;
  disabled?: boolean;
}

const AddSubAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [addSubAdmin] = useAddSubAdminMutation();
  const [getSubAdminByIdQuery] = useLazyGetSubAdminByIdQuery();
  const [updateSubAdmin] = useUpdateSubAdminByIdMutation();

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [newData, setNewData] = useState<DataType[]>([] as any);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [subAdminData, setSubAdminData] = useState<SubAdmin | null>(null);
  const [countryName, setCountryName] = useState("in");

  const translate = useTranslation();
  function createData(Module: string) {
    return { Module };
  }

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
    setCountryName(country?.countryCode);
  };

  const [permissions, setPermissions] = useState<DataType[]>([
    {
      _id: "1",
      name: "Dashboard",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "2",
      name: "Manage Users",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },

    {
      _id: "3",
      name: "Manage Agents",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "4",
      name: "Manage Subscription Plans",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "5",
      name: "Manage Promotional Code",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "6",
      name: "Manage Categories",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "7",
      name: "Manage Properties",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "8",
      name: "Manage Labels",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "9",
      name: "Promotional Tutorials",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "10",
      name: "Manage Payments",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "11",
      name: "Refund Requests",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "12",
      name: "Manage Notifications",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      _id: "13",
      name: "Analytics",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "14",
      name: "Settings",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
    {
      _id: "15",
      name: "Faqs",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: true,
    },
  ]);

  const getSubAdminById = async (id: string | undefined) => {
    try {
      const response = await getSubAdminByIdQuery({ subAmin_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setSubAdminData(response?.data);
        setPhoneCode(response?.data?.countryCode || "+91");
        setImage(response?.data?.image || "");
        const modifiedArr2 = permissions.map((item) => {
          const foundItem = response?.data?.permission?.find(
            (i: any) => i?.name === item.name
          );
          if (foundItem) {
            return {
              ...item,
              isView: foundItem.isView,
              isAdd: foundItem.isAdd,
              isDelete: foundItem.isDelete,
            };
          }
          return item;
        });
        setPermissions(modifiedArr2);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: subAdminData?.firstName || "",
      lastName: subAdminData?.lastName || "",
      email: subAdminData?.email || "",
      phone: subAdminData?.phone || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      firstName: Yup.string()
        .required("First name is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      lastName: Yup.string()
        .required("Last name is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      phone: Yup.string()
        .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      permissions?.length > 0 &&
        permissions.map((element) => {
          const obj = {
            name: element?.name,
            isAdd: element.isAdd,
            isView: element.isView,
            isDelete: element.isDelete,
          };
          if (obj) {
            newData?.push(obj);
            setNewData([...newData]);
          }
        });

      const hasSelectedPermission = permissions.some(
        (permission) =>
          permission.isAdd || permission.isView || permission.isDelete
      );

      if (!hasSelectedPermission) {
        showError("At least one permission must be selected");
        formik.setSubmitting(false); // Set submitting back to false
        return false; // Stop further execution
      }

      let data = {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: formik.values.email,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        phone: formik.values.phone,
        ...(image ? { image: image } : {}),

        permission: newData.filter((element) => {
          return (
            element.isView === true ||
            element.isAdd === true ||
            element.isDelete === true
          );
        }),
      };

      if (id && id !== "add") {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await updateSubAdmin({
            subAdminId: id,
            encryptedBody,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Sub-admin updated successfully");
            navigate("/manage-subAdmin");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await addSubAdmin(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            showToast("Sub-admin created sucessfully");
            navigate("/manage-subAdmin");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const onSelectChange = (key: any, type: string) => {
    let idx = permissions.findIndex((obj) => obj.name === key.name);

    if (type === "addEdit") {
      permissions[idx].isAdd = !key.isAdd;

      // If Add or Edit is checked, make sure View is checked
      if (key.isAdd || !permissions[idx].isAdd) {
        permissions[idx].isView = true;
      }

      // If Add or Edit is unchecked, uncheck Delete
      // if (!key.isAdd || !permissions[idx].isAdd) {
      //   permissions[idx].isDelete = false;
      // }
    } else if (type === "isview") {
      permissions[idx].isView = !key.isView;

      // If View is unchecked, uncheck Add, Edit, and Delete
      if (!key.isView) {
        permissions[idx].isAdd = false;
        permissions[idx].isDelete = false;
      }
    } else {
      permissions[idx].isDelete = !key.isDelete;

      // If Delete is checked, make sure View is checked
      if (key.isDelete) {
        permissions[idx].isView = true;
      }

      // If Delete is unchecked, uncheck Add and Edit
      // if (!key.isDelete) {
      //   permissions[idx].isAdd = false;
      //   permissions[idx].isView = false;
      // }
    }

    setPermissions([...permissions]);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData("Manage Users"),
    createData("Manage Agents"),
    createData("Manage Subscription Plans"),
    createData("Manage Categories"),
    createData("Manage Properties"),
    createData("Manage Labels"),
    createData("Manage Payments"),
    createData("Manage Notification"),
    createData("Manage Analytics"),
    createData("Manage CMS"),
    createData("Manage Faqs"),
  ];

  useEffect(() => {
    if (id && id !== "add") {
      getSubAdminById(id);
    }
  }, []);

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>
              {id
                ? translate?.main?.EditSubAdmin
                : translate?.main?.AddSubAdmin}
            </h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-subAdmin");
              }}
            >
              {translate?.main?.Back}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">
                      {" "}
                      {translate?.main?.Image}
                    </Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                            setFileName("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>
                            ) => {
                              const files = (event.target as HTMLInputElement)
                                .files;
                              if (files && files[0].type.includes("image")) {
                                setFileName(files[0].name);
                                setImage(URL.createObjectURL(files[0]));
                              } else {
                                setAlertType(0);
                                setShowAlert(true);
                                setAlertMessage(
                                  "This field only accepts images."
                                );
                              }
                            }}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {" "}
                      {translate?.main?.FullName}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="firstName"
                      variant="outlined"
                      fullWidth
                      placeholder={translate?.main?.Name}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    ></TextField>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className="custom_label">
                      {" "}
                      {translate?.main?.LastName}
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="lastName"
                      variant="outlined"
                      fullWidth
                      placeholder={translate?.main?.LastName}
                      id="lastName"
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {" "}
                      {translate?.main?.Email}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      helperText={formik.touched.email && formik.errors.email}
                    ></TextField>
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {translate?.main?.PhoneNumber}
                    </Typography>
                    <PhoneInput
                      enableSearch={true}
                      value={phoneCode + formik.values.phone}
                      country={"us"}
                      inputClass="phoneInput"
                      inputStyle={{ width: "100%" }}
                      buttonClass="phoneBtn"
                      placeholder={translate?.main?.PhoneNumber}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TableContainer className="table_container">
                      <Box className="heading"></Box>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell className="mn_hdng">
                              {" "}
                              {translate?.main?.Module}
                            </TableCell>
                            <TableCell className="mn_hdng">
                              {" "}
                              {translate?.main?.Add}/ {translate?.main?.Edit}
                            </TableCell>
                            <TableCell className="mn_hdng">
                              {" "}
                              {translate?.main?.View}
                            </TableCell>
                            <TableCell className="mn_hdng">
                              {" "}
                              {translate?.main?.Delete}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {permissions?.map((row, i) => (
                            <TableRow>
                              <TableCell className="mn_hdng">
                                {row?.name}
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  {...label}
                                  onChange={() =>
                                    onSelectChange(row, "addEdit")
                                  }
                                  checked={row?.isAdd || false}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  {...label}
                                  onChange={() => onSelectChange(row, "isview")}
                                  checked={row?.isView || false}
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  {...label}
                                  onChange={() => onSelectChange(row, "isdel")}
                                  checked={row?.isDelete || false}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translate?.main?.Save}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddSubAdmin;
