import LoginPage from "./auth/login";
import DashBoardPage from "./dashBoard";
import ManageUsers from "./users";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import Faq from "./faq";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageCms from "./manageCms";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";
import ManageSubAdmin from "./manageSubAdmin";
import AddSubAdmin from "./manageSubAdmin/add";
import RecievedNotifications from "./manageNotification/receivedNotification";
import ChangePassword from "./auth/changePassword";
import ManageProperties from "./manageProperties";
import ManagePayments from "./managePayment";
import Analytics from "./analytics";
import ManageSubscription from "./manageSubscription";
import AddSubscriptionPlan from "./manageSubscription/add";
import ManageCategories from "./manageCategories";
import ManageAgents from "./manageAgents";
import AddCategories from "./manageCategories/add";
import ManageLabels from "./manageLabels";
import PropertyDetails from "./manageProperties/details";
import AgentDetails from "./manageAgents/details";
import AgentsForm from "./manageAgents/form";
import SubCategory from "./manageCategories/subCategory";
import AddSubCategories from "./manageCategories/subCategory/form";
import PaymentDetails from "./managePayment/details";
import EditCategories from "./manageCategories/edit";
import EditSubscriptionPlan from "./manageSubscription/edit";
import AddProperty from "./manageProperties/add";
import ManageTutorial from "./manageTutorial";
import AddTutorial from "./manageTutorial/add";
import ManageAreas from "./manageAreas";
import ManageSubAreas from "./manageAreas/subAreas";
import AddAreas from "./manageAreas/add";
import AddSubAreas from "./manageAreas/subAreas/add";
import PermissionDenied from "./noPermission";
import ManageDiscount from "./manageDiscount";
import AddDiscount from "./manageDiscount/add";
import RefundRequests from "./refundRequests";
// import EditCategories from "./manageCategories/edit";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  DashBoardPage,
  ManageUsers,
  ForgotPasswordPage,
  VerifyOtp,
  Faq,
  ResetPassword,
  Profile,
  ManageCms,
  ManageNotifications,
  AddNotification,
  ManageSubAdmin,
  AddSubAdmin,
  RecievedNotifications,
  ChangePassword,
  ManageProperties,
  ManagePayments,
  Analytics,
  ManageSubscription,
  AddSubscriptionPlan,
  ManageCategories,
  ManageAgents,
  AddCategories,
  ManageLabels,
  PropertyDetails,
  AgentDetails,
  AgentsForm,
  SubCategory,
  AddSubCategories,
  PaymentDetails,
  EditCategories,
  EditSubscriptionPlan,
  AddProperty,
  ManageTutorial,
  AddTutorial,
  ManageAreas,
  ManageSubAreas,
  AddAreas,
  AddSubAreas,
  PermissionDenied,
  ManageDiscount,
  AddDiscount,
  RefundRequests
};
