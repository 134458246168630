import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { getFromStorage, STORAGE_KEYS } from "./constants";
import { useAppDispatch } from "./hooks/store";
import Pages from "./pages";
import UserDetails from "./pages/users/details";
import UsersForm from "./pages/users/form/usersForm";
import { resetAuth, setCredentials } from "./reducers/authSlice";

const Routing = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    if (token && window.location.pathname === "/") {
      dispatch(
        setCredentials({
          token: token,
          user: null,
        })
      );
      navigate("/dashboard", { replace: true });
    } else if (!token && window?.location?.pathname === "/") {
      dispatch(resetAuth());
      navigate("/", { replace: true });
    } else if (token) {
      dispatch(
        setCredentials({
          token: token,
          user: null,
        })
      );
      navigate(window?.location?.pathname);
    } else {
      dispatch(resetAuth());
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="/forgot-password" element={<Pages.ForgotPasswordPage />} />
      <Route path="/verify-otp" element={<Pages.VerifyOtp />} />
      <Route path="/reset-password" element={<Pages.ResetPassword />} />
      <Route path="/changepassword" element={<Pages.ChangePassword />} />
      <Route path="/dashboard" element={<Pages.DashBoardPage />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/manageusers" element={<Pages.ManageUsers />} />
      <Route path="/manageusers/userdetails/:id" element={<UserDetails />} />
      <Route path="/manageusers/usersform/:id" element={<UsersForm />} />
      <Route path="/faq" element={<Pages.Faq />} />
      <Route path="/cms" element={<Pages.ManageCms />} />
      <Route path="/manage-properties" element={<Pages.ManageProperties />} />
      <Route path="/manage-properties/add" element={<Pages.AddProperty />} />
      <Route
        path="/manage-properties/edit/:id"
        element={<Pages.AddProperty />}
      />
      <Route path="/manage-payments" element={<Pages.ManagePayments />} />
      <Route
        path="/manage-payments/details/:id"
        element={<Pages.PaymentDetails />}
      />
      <Route path="/analytics" element={<Pages.Analytics />} />
      <Route
        path="/manage-subscription"
        element={<Pages.ManageSubscription />}
      />
      <Route
        path="/manage-subscription/add"
        element={<Pages.AddSubscriptionPlan />}
      />
      <Route
        path="/manage-subscription/edit/:id"
        element={<Pages.AddSubscriptionPlan />}
      />
      <Route
        path="/manage-subscription/edit"
        element={<Pages.EditSubscriptionPlan />}
      />
      <Route
        path="/manage-notifications"
        element={<Pages.ManageNotifications />}
      />
      <Route
        path="/manage-notifications/add"
        element={<Pages.AddNotification />}
      />
      <Route
        path="/recieved-notifications"
        element={<Pages.RecievedNotifications />}
      />
      <Route
        path="/manage-properties/details/:id"
        element={<Pages.PropertyDetails />}
      />
      <Route path="/manage-labels" element={<Pages.ManageLabels />} />
      <Route path="/manage-agents" element={<Pages.ManageAgents />} />
      <Route
        path="/manage-agents/details/:id"
        element={<Pages.AgentDetails />}
      />
      <Route path="/manage-agents/edit/:id" element={<Pages.AgentsForm />} />
      <Route path="/manage-categories" element={<Pages.ManageCategories />} />
      <Route
        path="/manage-categories/sub-categories/:id"
        element={<Pages.SubCategory />}
      />
      <Route
        path="/manage-categories/sub-categories/add"
        element={<Pages.AddSubCategories />}
      />
      <Route path="/manage-categories/add" element={<Pages.AddCategories />} />
      <Route
        path="/manage-categories/edit/:id"
        element={<Pages.AddCategories />}
      />
      <Route path="/edit-categories/:id" element={<Pages.EditCategories />} />
      <Route path="/manage-subAdmin" element={<Pages.ManageSubAdmin />} />
      <Route path="/manage-subAdmin/add" element={<Pages.AddSubAdmin />} />
      <Route path="/manage-subAdmin/edit/:id" element={<Pages.AddSubAdmin />} />
      <Route path="/manage-tutorials" element={<Pages.ManageTutorial />} />
      <Route path="/manage-tutorials/add" element={<Pages.AddTutorial />} />
      <Route
        path="/manage-tutorials/edit/:id"
        element={<Pages.AddTutorial />}
      />
      <Route path="/manage-areas" element={<Pages.ManageAreas />} />
      <Route
        path="/manage-areas/sub-areas/:id"
        element={<Pages.ManageSubAreas />}
      />
      <Route
        path="/manage-areas/sub-areas/add"
        element={<Pages.AddSubAreas />}
      />
      <Route
        path="/manage-areas/sub-areas/edit/:id"
        element={<Pages.AddSubAreas />}
      />
      <Route path="/manage-areas/add" element={<Pages.AddAreas />} />
      <Route path="/manage-areas/edit/:id" element={<Pages.AddAreas />} />
      <Route path="/permission-denied" element={<Pages.PermissionDenied />} />
      <Route path="/manage-discount" element={<Pages.ManageDiscount />} />
      <Route path="/manage-discount/add" element={<Pages.AddDiscount />} />
      <Route path="/manage-discount/edit/:id" element={<Pages.AddDiscount />} />
      <Route path="/refund-requests" element={<Pages.RefundRequests />} />
    </Routes>
  );
};

export default Routing;
