import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import "../../pages/users/Users.scss";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  useLazyGetAgentRequestsQuery,
  useLazyGetUserByIdQuery,
} from "../../services/users";
import { AllUser } from "../../types/General";
import { showError } from "../../constants";
import useTranslation from "../../hooks/Translation";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AgentDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [getUserData] = useLazyGetUserByIdQuery();
  const [agentRequests] = useLazyGetAgentRequestsQuery();
  const [useDetails, setUserDetails] = useState<any>();
  const [requestsData, setRequestsData] = useState<any>();

  console.log(requestsData, "requestsData");

  const translate = useTranslation();
  const getUserDetailById = async (id: string | undefined) => {
    try {
      const response = await getUserData({ user_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setUserDetails(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const getAgentsRequestsApi = async (id: string | undefined) => {
    try {
      const response = await agentRequests({ user_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setRequestsData(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    getUserDetailById(id);
    getAgentsRequestsApi(id);
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate?.agents?.ViewAgentProfile}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-agents");
            }}
          >
            {translate?.main?.Back}
          </Button>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Profile" {...a11yProps(0)} />
              <Tab label="License Documents" {...a11yProps(1)} />
            </Tabs>

            <CustomTabPanel value={value} index={0}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2} className="view_box">
                  <Grid item xs={10} className="view_box_list">
                    <Grid
                      item
                      lg={2}
                      md={2}
                      sm={6}
                      xs={12}
                      sx={{ paddingBottom: 5 }}
                    >
                      <figure className="profile_img">
                        <img
                          src={
                            useDetails?.image
                              ? useDetails?.image
                              : "/static/images/user_placeholder.png"
                          }
                          alt=""
                        />
                      </figure>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translate?.main?.Name}
                          </Typography>
                          <Typography component="p">
                            {useDetails?.fullName || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translate?.main?.PhoneNo}
                          </Typography>
                          <Typography component="p">{`${
                            useDetails?.countryCode || ""
                          }  ${useDetails?.phone || "-"}`}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translate?.main?.Email}
                          </Typography>
                          <Typography component="p">
                            {useDetails?.email || "-"}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translate?.main?.AcountStatus}
                          </Typography>
                          <Typography component="p">
                            {useDetails?.isBlocked == false
                              ? "Active"
                              : "Inactive"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            Total Requests Used
                          </Typography>
                          <Typography component="p">
                            {useDetails?.subscriptionPlan?.acceptRequests ||
                              "0"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            Not Accepted Requests
                          </Typography>
                          <Typography component="p">
                            {requestsData?.pendingAgentRequests || "0"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            Total Requests Left
                          </Typography>
                          <Typography component="p">
                            {" "}
                            {useDetails?.subscriptionPlan?.acceptRequestsLeft ||
                              "0"}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item mt={3} xs={12} className="view_box_list">
                        <Typography
                          className="detail_title mn_hdng"
                          component="h2"
                          mb={3}
                        ></Typography>
                        <Grid item mt={3} xs={12} className="view_box_list">
                          <Typography
                            className="detail_title mn_hdng"
                            component="h2"
                            mb={3}
                          >
                            {translate?.main?.NormalListingCredits}
                          </Typography>
                          <Grid container spacing={3}>
                            <Grid item lg={3} md={3} sm={6} xs={12}>
                              <Box>
                                <Typography component="h5">
                                  {translate?.main?.TotalCredits}
                                </Typography>
                                <Typography component="p">
                                  {" "}
                                  {useDetails?.normalCred || "0"}
                                </Typography>
                              </Box>
                            </Grid>
                            {/* <Grid item xs={3}>
                          <Box>
                            <Typography component="h5">
                            {translate?.main?.Totalcreditsused}
                            </Typography>
                            <Typography component="p">-</Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">
                            {translate?.main?.TotalCreditsLeft}
                            </Typography>
                            <Typography component="p">-</Typography>
                          </Box>
                        </Grid> */}
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item lg={3} md={3} sm={6} xs={12}>
                              <Box>
                                <Typography component="h5">
                                  {translate?.main?.TotalCredits2}
                                </Typography>
                                <Typography component="p">
                                  {" "}
                                  {useDetails?.subscriptionPlan?.normalCredit ||
                                    "0"}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <Box>
                                <Typography component="h5">
                                  {translate?.main?.Totalcreditsused2}
                                </Typography>
                                <Typography component="p">
                                  {useDetails?.subscriptionPlan?.normalCredit -
                                    useDetails?.subscriptionPlan
                                      ?.normalCreditLeft || "0"}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item lg={3} md={3} sm={6} xs={12}>
                              <Box>
                                <Typography component="h5">
                                  {translate?.main?.TotalCreditsLeft2}
                                </Typography>
                                <Typography component="p">
                                  {useDetails?.subscriptionPlan
                                    ?.normalCreditLeft || "0"}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item mt={3} xs={12} className="view_box_list">
                          <Typography
                            className="detail_title mn_hdng"
                            component="h2"
                            mb={3}
                          >
                            {translate?.main?.HighlightedListingCredits}
                          </Typography>
                          <Grid container spacing={3}>
                            <Grid item lg={3} md={3} sm={6} xs={12}>
                              <Box>
                                <Typography component="h5">
                                  {translate?.main?.TotalCredits}
                                </Typography>
                                <Typography component="p">
                                  {useDetails?.highlightCred || "0"}
                                </Typography>
                              </Box>
                            </Grid>
                            {/* <Grid item xs={3}>
                          <Box>
                            <Typography component="h5">
                            {translate?.main?.Totalcreditsused}
                            </Typography>
                            <Typography component="p">-</Typography>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                          <Box>
                            <Typography component="h5">
                            {translate?.main?.TotalCreditsLeft}
                            </Typography>
                            <Typography component="p">-</Typography>
                          </Box>
                        </Grid> */}
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item lg={3} md={3} sm={6} xs={12}>
                              <Box>
                                <Typography component="h5">
                                  {translate?.main?.TotalCredits2}
                                </Typography>
                                <Typography component="p">
                                  {useDetails?.subscriptionPlan
                                    ?.highlightCredit || "0"}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <Box>
                                <Typography component="h5">
                                  {translate?.main?.Totalcreditsused2}
                                </Typography>
                                <Typography component="p">
                                  {useDetails?.subscriptionPlan
                                    ?.highlightCredit -
                                    useDetails?.subscriptionPlan
                                      ?.highlightCreditLeft || "0"}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item lg={3} md={3} sm={6} xs={12}>
                              <Box>
                                <Typography component="h5">
                                  {translate?.main?.TotalCreditsLeft2}
                                </Typography>
                                <Typography component="p">
                                  {" "}
                                  {useDetails?.subscriptionPlan
                                    ?.highlightCreditLeft || "0"}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item mt={3} xs={12} className="view_box_list">
                        <Typography
                          className="detail_title mn_hdng"
                          component="h2"
                          mb={3}
                        >
                          {translate?.main?.PlanDetails}
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box>
                              <Typography component="h5">
                                {" "}
                                {translate?.main?.PlanName}
                              </Typography>
                              <Typography component="p">
                                {useDetails?.subscriptionPlan?.planName || "-"}
                              </Typography>
                            </Box>
                          </Grid>
                          {/* <Grid item xs={3}>
                        <Box>
                          <Typography component="h5">Plan Type</Typography>
                          <Typography component="p">1 Month</Typography>
                        </Box>
                      </Grid> */}
                          <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box>
                              <Typography component="h5">
                                {" "}
                                {translate?.main?.PlanPrice}
                              </Typography>
                              <Typography component="p">
                                {useDetails?.subscriptionPlan?.price || "-"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item lg={3} md={3} sm={6} xs={12}></Grid>
                          <Grid item lg={3} md={3} sm={6} xs={12}></Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <CardContent sx={{ p: 1 }}>
                <div
                  className="upload_image_preview2"
                  style={{ width: "400px" }}
                >
                  <CardMedia
                    component="img"
                    image="/static/images/pdf.png"
                    alt="photo"
                    sx={{ width: 200, height: 200, objectFit: "contain" }}
                    onClick={() => window.open(useDetails?.licenseDocument)}
                  />
                </div>
              </CardContent>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AgentDetails;
